import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";

const steps = [
	{
		h5: "Upload candidate’s information",
		p:
			"A relevant document with the candidate's name, address and father’s name is uploaded.",
	},
	{
		h5: "Scan Global Databases",
		p:
			"The Global Database is thoroughly scanned to  check if the candidate is involved in criminal activities - directly or indirectly",
	},
	{
		h5: "Get notified of adverse actions",
		p: "Get prompt, in-depth report on adverse actions if any.",
	},
];

const testimonials = [
	{
		name: "Pushpita G ",
		company: "EZOPS Technologies India Pvt. Ltd.",
		p: `I'm already in love with the UX. It's extremely user-friendly and self-explanatory. All you have to do is to
			add the candidate and it takes care of the rest. Right from running checks to real-time updates, flags, and reminders -
			SpringVerify does the job like a breeze.`,
	},
	{
		name: "Nandita N",
		company: "Umlaut Pvt. Ltd.",
		p: `SpringVerify is a true all-rounder when it comes to background verifications. Most services I've used in the past have
			always had compromises. But SpringVerify has been exceptional and impressive - right from the variety of checks it offers,
			its reliablity to 24x7 customer support.`,
	},
	{
		name: "Purushottam N",
		company: "Speegile Consulting",
		p: `No doubt SpringVerify has transformed the way we run background checks. I love the variety of checks it offers
			that delivers accurate results in a quick and efficient manner.`,
	},
];

export default () => (
	<div className={`${styles.solutions} ${styles.global}`}>
		<SEO
			title="Global Background Screening Service | SpringVerify India"
			desc="Confirm your candidate's background by running a global database check for a safe and secure hiring."
			url="https://in.springverify.com/screenings/global-database-verification/"
			imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(Global+Database+Verification).jpg"
		/>
		<div className={styles.topSection}>
			<Header />
			<Banner
				h1="Hiring made compliant with Global Database Verification"
				p="Know if your candidate has been involved in serious or organized crimes - directly or indirectly. SpringVerify scans the global database to ensure safe and secure hiring."
				img={"https://assets-sv-in.gumlet.io/LP/Global/banner.svg"}
			/>
		</div>
		<Brands />
		<section>
			<SectionTemplate
				img="Global/need.svg"
				h2="Importance of Global Database Verification"
				p={`Global Database Verification reveals vital information on international or domestic criminal involvement.
				SpringVerify thoroughly screens databases listed on Regulatory, Compliance, Debarment, PEP and Financial
				Sanctions Authorities globally. This can help you to:`}
				ul={[
					"Build a safe and secure workplace",
					"Be compliant with government regulations",
					"Recruit dependable individuals with clean history",
				]}
			/>
		</section>
		<section>
			<SectionTemplate
				reversed
				img="Global/using.svg"
				h2="Information you’ll know from Global Database Verifications"
				p={`Global Database Verification helps employers with more insights on their candidates by scanning the
				global database which contains world-wide information. Here’s what employers will come to know about,`}
			>
				<div className={styles.using}>
					{[
						"Involvement in serious criminal activities and syndicates",
						"History of criminal records locally and internationally ",
						"Latest reports on active crime monitoring data",
					].map((e, i) => (
						<div>
							<img
								data-src={`https://assets-sv-in.gumlet.io/LP/Global/Using/${
									i + 1
								}.svg`}
								alt=""
							/>
							<p>{e}</p>
						</div>
					))}
				</div>
			</SectionTemplate>
		</section>
		<ThreeSteps
			steps={steps}
			candidate="https://assets-sv-in.gumlet.io/LP/Global/candidate.svg"
			path="Global"
		/>
		<section>
			<SectionTemplate
				img="Global/solution.svg"
				h2="SpringVerify’s robust solution for Global Database Verifications"
				p="SpringVerify helps employers scale their hiring without painful bottlenecks.
				Here’s how SpringVerify helps you make better hiring decision instantly."
				ul={[
					"Seamless API integration with pre-built HRMS systems",
					"Class leading turnaround times",
					"World’s first Instant Verification on Whatsapp",
					"Flexible and transparent pricing (Our most decorated USP)",
				]}
			/>
		</section>
		<Testimonials testimonials={testimonials} path="Global" />
		<GetStarted
			img="Global/get-started.svg"
			h3="Ready to get started with us?"
			p="Discover your candidate's criminal involvement and make effective hiring decisions without hassle."
		/>
		<Footer />
	</div>
);
